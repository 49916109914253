import React, { useState, useRef } from "react";
import { getUser } from "../../../../helper/auth_helper";
import { useTranslation } from "react-i18next";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";

import Modal from "react-modal";
import "./buildingInformation.modules.css";
import bin from "../../../../images/bin.png";

const BuildingInformation = ({ projectId, orgId, reloadAdd, project }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [rows, setRows] = useState({
    name: "",
    pandId: "",
    streetName: "",
    city: "",
    postcode: "",
    country: "",
    visualizationModel: false,
    babyModel: null,
  });
  const [buildingImage, setBuildingImage] = useState(null);
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleChange = (field, value) => {
    setRows({
      ...rows,
      [field]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds 2 MB.");
      setRows({
        ...rows,
        babyModel: null,
      });
      setBuildingImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null; // Clear the file input
      }
      return;
    }
    setRows({
      ...rows,
      babyModel: file,
    });
    setBuildingImage(URL.createObjectURL(file));
  };
  

  const handleDeleteImage = () => {
    setRows({
      ...rows,
      babyModel: null,
    });
    setBuildingImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const resetState = () => {
    setRows({
      name: "",
      pandId: "",
      streetName: "",
      city: "",
      postcode: "",
      country: "",
      visualizationModel: false,
      babyModel: null,
    });
    setBuildingImage(null);
    setValidationErrors({});
  };

  const submitBuildingInput = async () => {
    const requiredFields = [
      "name",
      "country",
      "streetName",
      "city",
      "postcode",
    ];

    const fieldLabels = {
      name: t("name_editProjectTable"),
      country: t("country_editProjectTable"),
      streetName: t("streetName_editProjectTable"),
      city: t("city_editProjectTable"),
      postcode: t("postCode_editProjectTable"),
    };

    const newValidationErrors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!rows[field]) {
        newValidationErrors[field] = `${fieldLabels[field]} ${t(
          "required_errorMessage"
        )}`;
        hasErrors = true;
      }
    });
    if (hasErrors) {
      setValidationErrors(newValidationErrors);
      return;
    }

    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    formData.append("name", rows.name);
    formData.append("pandId", rows.pandId);
    formData.append("streetName", rows.streetName);
    formData.append("city", rows.city);
    formData.append("postcode", rows.postcode);
    formData.append("country", rows.country);
    formData.append("visualizationModel", rows.visualizationModel);
    formData.append("projectId", projectId);
    formData.append("organizationId", orgId);
    if (rows.babyModel) {
      formData.append("babyModel", rows.babyModel);
    }

    try {
      await api.post(apiUrl, formData, { headers });
      toast(`${t("buildingAdded")}`);
      setModalIsOpen(false);
      resetState();
      reloadAdd();
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      } else {
        toast.error(`${t("errorInviting_errorMessage")}`);
      }
    }
  };

  return (
    <div>
      {!project.readOnly ? (
        <button
          className="btn btn-success bulkUploadBtn"
          onClick={() => setModalIsOpen(true)}
        >
          {t("pageHeader_buildingInformation")}
        </button>
      ) : (
        <button className="btn btn-success bulkUploadBtn" disabled>
          {t("pageHeader_buildingInformation")}
        </button>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          resetState();
        }}
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",
          },
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div>
              <h2>{t("addBuilBtn_buildingInformation")}</h2>
            </div>
            <div>
              <button
                className="closeBtn"
                onClick={() => {
                  setModalIsOpen(false);
                  resetState();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="25px"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="userdiv">
          <div className="addressDiv-content">
            <table>
              <thead>
                <tr>
                  <th>
                    {t("name_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>{t("pandId_editProjectTable")}</th>
                  <th>
                    {t("streetName_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("city_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("postCode_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("country_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th className="visual">{t("visualizationModel_editProjectTable")} </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      value={rows.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                      maxLength={50}
                      className={validationErrors.name ? "input-error" : ""}
                    />
                    {validationErrors.name && (
                      <span className="error-message">
                        {validationErrors.name}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={rows.pandId}
                      onChange={(e) => handleChange("pandId", e.target.value)}
                      maxLength={50}
                      // className={validationErrors.pandId ? "input-error" : ""}
                    />
                    {/* {validationErrors.pandId && <span className="error-message">{validationErrors.pandId}</span>} */}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={rows.streetName}
                      onChange={(e) =>
                        handleChange("streetName", e.target.value)
                      }
                      maxLength={50}
                      className={
                        validationErrors.streetName ? "input-error" : ""
                      }
                    />
                    {validationErrors.streetName && (
                      <span className="error-message">
                        {validationErrors.streetName}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={rows.city}
                      onChange={(e) => handleChange("city", e.target.value)}
                      maxLength={50}
                      className={validationErrors.city ? "input-error" : ""}
                    />
                    {validationErrors.city && (
                      <span className="error-message">
                        {validationErrors.city}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={rows.postcode}
                      onChange={(e) => handleChange("postcode", e.target.value)}
                      maxLength={50}
                      className={validationErrors.postcode ? "input-error" : ""}
                    />
                    {validationErrors.postcode && (
                      <span className="error-message">
                        {validationErrors.postcode}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={rows.country}
                      onChange={(e) => handleChange("country", e.target.value)}
                      maxLength={50}
                      className={validationErrors.country ? "input-error" : ""}
                    />
                    {validationErrors.country && (
                      <span className="error-message">
                        {validationErrors.country}
                      </span>
                    )}
                  </td>
                  <td className="visualization-td">
                    <input
                      type="checkbox"
                      checked={rows.visualizationModel}
                      onChange={(e) =>
                        handleChange("visualizationModel", e.target.checked)
                      }
                    />
                  </td>
                 
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="babyModel-block">
            <div className="babyModel-block-heading">
            <th>{t("babyModel_editProjectTable")}</th>
            </div>

          <div className="babyModel-block-data">
            <td >
                    <input
                      ref={fileInputRef}
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    {buildingImage && (
                      <div>
                        <img
                          src={buildingImage}
                          alt="Baby Model Preview"
                          className="baby-model-img"
                        />
                        <button class="btn btn-light delete-button babyModel-delete" onClick={handleDeleteImage}>
                          <img src={bin} alt="Delete Image" class="delete-img"/>
                          <span class="hover-text">Remove Image</span>
                        </button>
                      </div>
                    )}
                  </td>
          </div>
         
          </div>
          <button
            className="btn btn-success modal-save-button"
            onClick={submitBuildingInput}
          >
            {t("submit")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default BuildingInformation;