import React, { useCallback, useEffect, useState } from "react";
import styles from "./TableInfo.module.css";
import { useTranslation } from "react-i18next";
import homeAccordian from "../../../../images/home-accordian.png";
import babyModalReplacement from "../../../../images/default-babyModel.png";
import refreshButton from "../../../../images/refresh-button.png";
import { fetchBuildingDetails } from "../../Api/fetchBuildingDetails";
import { format } from "date-fns";
import { convertUtcToAmsterdamTime } from "../../../commonUtility/timeConvertion";

export const TableHeader = ({
  buildingId,
  setRefreshInitiated,
  projectRole,
}) => {
  const [buildingDetails, setBuildingDetails] = useState({});
  const { t } = useTranslation();
  const fetchBuildingData = useCallback(async () => {
    const buildingData = await fetchBuildingDetails(buildingId);
    setBuildingDetails(buildingData.apiBuildingData);
  }, [buildingId]);

  useEffect(() => {
    fetchBuildingData();
  }, [fetchBuildingData]);

  const {
    projectName,
    buildingName,
    buildingId: id,
    streetName,
    postcode,
    city,
    reportDetails = {},
    babyModel,
  } = buildingDetails;

  const { generatedBy, dateCreated, timeCreated } = reportDetails;

  const formattedDateCreated = dateCreated
    ? format(new Date(dateCreated), "dd/MM/yyyy")
    : "-";

  const formattedTimeCreated = convertUtcToAmsterdamTime(timeCreated);

  const refreshAnnotations = () => {
    setRefreshInitiated(true);
  };

  return (
    <div className={styles.formHead}>
      <div className={styles.jsonReport}>
        <div className={styles.buildingDetailsJsonBlock}>
          <div className={styles.buildingDetailsHeading}>
            <img
              className={styles.homeAccordian}
              src={homeAccordian}
              alt="homeAccordian"
            />
            <h2>{t("buildingDetails")}</h2>
          </div>
          <div className={styles.buildingDetailsData}>
            <div className={styles.buildingRecord}>
              <div className={styles.buildingDetailsImg}>
                <img
                  src={
                    babyModel
                      ? `data:image/png;base64,${babyModel}`
                      : babyModalReplacement
                  }
                  className={styles.babyModalImg}
                  alt="building"
                />
              </div>
              <div className={styles.buildingDetailsSubData}>
                <div>
                  <p>{t("projectName_table")}:</p>
                  <p>{t("building_editProjectTable")}:</p>
                  <p>{t("buildingId")}:</p>
                  <p>{t("address")}:</p>
                  <p>{t("postCode")}:</p>
                  <p>{t("city")}:</p>
                </div>
                <div className={styles.buildingDetailsSubDataResult}>
                  <p>{projectName}</p>
                  <p>{buildingName}</p>
                  <p>{id}</p>
                  <p>{streetName}</p>
                  <p>{postcode}</p>
                  <p>{city}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.reportDetails}>
          <div>
            <div className={styles.reportDetailsHeading}>
              <img
                className={styles.homeAccordian}
                src={homeAccordian}
                alt="homeAccordian"
              />
              <h2>{t("reportDetails")}</h2>
            </div>
            <div className={styles.reportDetailsContent}>
              <div className={styles.reportDetailsContentSubData}>
                <div>
                  <p>{t("generatedBy")}:</p>
                  <p>{t("dateCreated")}:</p>
                  <p>{t("time")}:</p>
                </div>
                <div className={styles.reportDetailsContentSubDataResult}>
                  <p>{generatedBy}</p>
                  <p>{formattedDateCreated}</p>
                  <p>{formattedTimeCreated}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.refreshDetails}>
              <div className={styles.refreshtDetailsHeading}>
                <h2>{t("refresh")}</h2>
              </div>
              <div className={styles.refreshDetailsContent}>
                <div>
                  <img
                    src={refreshButton}
                    onClick={
                      projectRole === "SUPRMEN_ADMIN" ||
                      projectRole === "PROJECT_LEADER"
                        ? refreshAnnotations
                        : undefined // No action if the role is not allowed
                    }
                    className={`${
                      !(
                        projectRole === "SUPRMEN_ADMIN" ||
                        projectRole === "PROJECT_LEADER"
                      )
                        ? styles.disabledButton
                        : ""
                    }`}
                    alt="refresh Icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
