import React, { useState, useRef, useEffect } from "react";
import checkboxSelection from "./../../images/checkbox-selection-dropdown.png";
import { useTranslation } from "react-i18next";
import styles from "./dropdownFilter.module.css";
import searchbar from "./../../images/filter-searchBar.png";
import arrowSetting from "./../../images/downarrow-setting.png";

export const FilterDropdown = ({
  columns,
  isDropdownOpen,
  handleSearchElementFilter,
  ElementfilterRequestBody,
  ResetFilter,
  handleElementFilterSearch,
  resetFilter,
  allReset,
  setAllReset,
  toggleElementDropdown,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleCheckboxChange = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = prevSelectedColumns.includes(column)
        ? prevSelectedColumns.filter((item) => item !== column)
        : [...prevSelectedColumns, column];

      handleElementFilterSearch(updatedColumns);
      setIsAllSelected(updatedColumns.length === columns.length);
      return updatedColumns;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedColumns([]);
      handleElementFilterSearch([]);
    } else {
      setSelectedColumns(columns);
      handleElementFilterSearch(columns);
    }
    setIsAllSelected(!isAllSelected);
  };

  const resetAll = () => {
    setSelectedColumns([]);
    setIsAllSelected(false);
    ResetFilter("element");
    resetFilter("element");
  };

  useEffect(() => {
    if (allReset === true) {
      setSelectedColumns([]);
      setIsAllSelected(false);
      ResetFilter();
      setAllReset(false);
    }
  }, [ResetFilter, allReset, setAllReset]);

  return (
    <div className={styles.selectionFilterButton}>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`${styles.selectionDropdown}  ${styles.elementDropdown}`}
        >
          <div className="selection-container">
            <div className={styles.selectionContainerContent}>
              <label>{t("elemnentFilterSettings")}</label>
              <img
                src={arrowSetting}
                alt="arrow Icon"
                onClick={() => toggleElementDropdown()}
              />
            </div>

            <ul>
              <div className={styles.selection}>
                <button
                  onClick={() => resetAll()}
                  className={styles.resetSelection}
                >
                  {t("resetToDefault")}
                </button>
              </div>

              <div className={styles.searchBoxFilter}>
                <img src={searchbar} alt="home icon" />
                <input
                  className={styles.checkInputProjectList}
                  type="text"
                  placeholder={t("searchForFilterOptions")}
                  value={ElementfilterRequestBody.current.searchText || ""}
                  onChange={handleSearchElementFilter}
                />
              </div>
              <div className={styles.mainSelectionLIContent}>
                <div className={styles.selectionLiContent}>
                  <li>
                    <label>
                      <img
                        src={checkboxSelection}
                        alt="Checkbox Selection Dropdown"
                      />
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAllChange}
                      />
                      {t("all")}
                    </label>
                  </li>
                  {columns.map((column) => (
                    <li key={column}>
                      <label>
                        <img
                          src={checkboxSelection}
                          alt="Checkbox Selection Dropdown"
                        />
                        <input
                          type="checkbox"
                          checked={selectedColumns.includes(column)}
                          onChange={() => handleCheckboxChange(column)}
                        />
                        {column}
                      </label>
                    </li>
                  ))}
                </div>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
