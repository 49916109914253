import React, { useEffect, useState } from "react";
import cube from "../../../../images/cube.png";
import caution from "../../../../images/caution.png";
import homeAccordian from "../../../../images/home-accordian.png";
import analysis from "../../../../images/analysis.png";
import { Circles } from "react-loader-spinner";
import babyModalReplacement from "../../../../images/default-babyModel.png";
import addressDeatils from "../../../../images/address-detail.png";
import { exportBuildingReport } from "../api/downloadReports";
import { startAnalysis } from "../api/startAnalysis";
import { useAccess } from "../../context/accessControl";
import { AddressList } from "../addressList";
import styles from "./secondDropdown.module.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../pathComponents/tooltip/CopyToClipboardButton";
import { useProjectRole } from "../../../../context/projectRoleContext";

export const BuildingDetailsTable = ({
  item,
  buildingId,
  addressReportList,
  rowHeadData,
  buildingReportList,
  drivePath,
  processingStatus,
  setCallFunction,
  setBringId,
}) => {
  const { suprmenAdmin, orgAdmin } = useAccess();
  const { t } = useTranslation();
  const { dataProjectRole } = useProjectRole();
  const [projectRole, setProjectRole] = useState();

  const projectId = item.project.id;

  const handleAnalysisClick = async () => {
    try {
      await startAnalysis(buildingId);
    } catch (error) {
      toast.error("An unexpected error occurred: " + error.message);
    }
  };

  const handleButtonClick = (reportId, fileName, fileId) => {
    if (fileId) {
      exportBuildingReport(reportId, fileName);
    } else {
      toast.error(`${t("fileDownload_errorMessage")}`);
    }
  };

  const getAnalysisButtonClass = (status) => {
    const conditionalClasses = suprmenAdmin
      ? styles.supAdminClass
      : styles.projectLeaderClass;
    switch (status) {
      case "STARTED":
        return `${conditionalClasses} ${styles.red}`;
      case "IN_PROGRESS":
        return `${conditionalClasses} ${styles.orange}`;
      case "COMPLETED":
        return `${conditionalClasses}  ${styles.green}`;
      case "FAILED":
        return `${styles.failedStatusBtn}`;
      default:
        return `${conditionalClasses} ${styles.gray}`;
    }
  };
  const getAnalysisNames = (status) => {
    switch (status) {
      case "NOT_STARTED":
        return `${t("reports")}`;
      case "IN_PROGRESS":
        return `${t("processing")}`;
      case "COMPLETED":
        return `${t("reports")}`;
      case "FAILED":
        return `${t("retryProcessing")}`;

      default:
        return `${t("reports")}`;
    }
  };

  const getButtonClass = (reportItem) => {
    if (!reportItem.reportId) {
      return suprmenAdmin ? "defaultGrayColor" : "defaultNotAdmin";
    }

    let typeClass;
    switch (reportItem.reportType) {
      case "NEN2767":
        typeClass = "nen";
        break;
      case "Export":
        typeClass = "exportlist";
        break;
      case "MJOP":
        typeClass = "mjoplist";
        break;
      case "Analysis":
        typeClass = "analsislist";
        break;
      default:
        typeClass = "color-default";
    }

    const extraClass = !suprmenAdmin ? styles.activeBtnMargin : "";

    return `actionsBtn3 active ${typeClass} ${extraClass}`.trim();
  };
  useEffect(() => {
    const result = dataProjectRole[projectId].role;
    setProjectRole(result);
  }, [dataProjectRole, projectId]);

  return (
    <tr>
      <td colSpan="8">
        <div className="dropdown-content">
          <div className="building-documents">
            <div className="building-details-block">
              <div className="building-details-heading">
                <img
                  className="homeaccordian"
                  src={homeAccordian}
                  alt="homeAccordian"
                />
                <h2>{t("buildingDetails")}</h2>
              </div>
              <div className="building-details-data">
                <div className="building-record">
                  <div className="building-details-img">
                    <img
                      src={
                        item.babyModel
                          ? `data:image/png;base64,${item.babyModel}`
                          : babyModalReplacement
                      }
                      className="babyModalImg"
                      alt="building"
                    />
                  </div>
                  <div className="building-details-sub-data">
                    <h5>{item.name}</h5>
                    <div className="street-post">
                      <p>{item.streetName}</p>
                      <p>{item.postcode}</p>
                    </div>

                    <h5 className="panid">Pand ID</h5>
                    <span>{item.pandId}</span>
                  </div>
                </div>
                <div className="building-details-actions">
                  <div className="informationButtons">
                    <div className="buttonRow">
                      <button
                        type="submit"
                        onClick={() => {
                          if (processingStatus === "COMPLETED") {
                            window.location.href = `/report/annotations/${buildingId}/${projectRole}`;
                          } else if (processingStatus === "IN_PROGRESS") {
                            toast.error(`${t("analyse_error")}`);
                          } else {
                            handleAnalysisClick();
                          }
                        }}
                        disabled={
                          !(
                            projectRole === "SUPRMEN_ADMIN" ||
                            projectRole === "PROJECT_LEADER"
                          ) && processingStatus !== "COMPLETED"
                        }
                        className={`${getAnalysisButtonClass(
                          processingStatus
                        )} ${
                          !(
                            projectRole === "SUPRMEN_ADMIN" ||
                            projectRole === "PROJECT_LEADER"
                          ) && processingStatus !== "COMPLETED"
                            ? styles.disabledButton
                            : ""
                        }`}
                      >
                        {processingStatus === ("IN_PROGRESS" || "FAILED") ? (
                          <Circles
                            type="TailSpin"
                            color="white"
                            height={18}
                            width={18}
                          />
                        ) : (
                          <img src={analysis} alt="analysis-icon" />
                        )}

                        <a>{getAnalysisNames(processingStatus)}</a>
                      </button>
                      {processingStatus === "FAILED" && (
                        <>
                          <img
                            class={styles.cautionImage}
                            src={caution}
                            alt="error"
                          />
                        </>
                      )}
                    </div>
                    {buildingReportList.map((reportItem, index) => (
                      <React.Fragment key={index}>
                        <div className="buttonRow">
                          <button
                            className={getButtonClass(reportItem)}
                            type="button"
                            onClick={() =>
                              handleButtonClick(
                                reportItem.reportId,
                                reportItem.fileName,
                                reportItem.fileId
                              )
                            }
                            disabled={!reportItem.reportId}
                          >
                            <img
                              src={
                                reportItem.reportType === "Export"
                                  ? analysis
                                  : cube
                              }
                              alt="NEN2767"
                            />
                            <a>{reportItem.reportType}</a>
                          </button>
                          {drivePath && suprmenAdmin && (
                            <CopyToClipboardButton link={drivePath} />
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="address-documents">
            <div className="address-heading-block">
              <div className="address-details">
                <img
                  className="addressDeatils"
                  src={addressDeatils}
                  alt="addressDeatils"
                />
                <h2>{t("addressDetails")}</h2>
              </div>
            </div>
            <div className="address-table-content">
              <AddressList
                buildingId={buildingId}
                addressReportList={addressReportList}
                rowHeadData={rowHeadData}
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
