import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Circles } from "react-loader-spinner";
import "./editOrg.modules.css";
import api from "../../helper/auth_interceptor";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import { getUser } from "../../helper/auth_helper";
import hamburger from "../../images/hamburger.jpg";
import { BdlListMod } from "./components/bndlListModal";
import { PurchaseHistory } from "./components/purchaseHistory";
import { AdminDetails } from "./components/adminDetails";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import { toast } from "react-toastify";


const orgDetailsDefault = {

  // object declarations
  organization: {
    id: undefined,
    name: "",
    organizationType: "",
    houseNo: undefined,
    streetName: "",
    city: "",
    postcode: "",
    createDate: "",
    endDate: "",
    updateDate: "",
    startDate: "",
  },
  admin: {
    id: undefined,
    username: "",
    firstName: "",
    prefix: null,
    lastName: "",
    email: "",
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      houseNo: undefined,
      streetName: "",
      city: "",
      postcode: "",
      createDate: "",
      endDate: "",
      updateDate: "",
      startDate: "",
    },
    createDate: "",
    updateDate: "",
    endDate: null,
    status: "",
    language: null,
    jobTitle: null,
    contactNo: "",
    startDate: null,
  },
};

function EditOrganisation({ suprmenAdmin, orgAdmin, viewer, regular }) {

   //constants, objects and state declarations

  const params = useParams();
  const now = moment();
  const todayDate = now.format("DD-MM-YYYY");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [info, setInfo] = useState({});
  const [list, setList] = useState([]);

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
     bundleIdList: [],
    discount: null,
    minInactiveBuildingPercent: null,
  });


  const [orgDetails, setOrgDetails] = useState(orgDetailsDefault);
  const [validationErrors, setValidationErrors] = useState({});

  
  const [updatedUserDetails, setUpdatedUserDetails] = useState({
    bundleIdList: [],
    discount: null,
    minInactiveBuildingPercent: null,
  });

  const handleUpdateUserDetails = (updatedUserDetails) => {
    setUserDetails({
      ...userDetails,
      ...updatedUserDetails,
    });
  };

  const updateOrg = (value, key) => {
    setOrgDetails({
      ...orgDetails,
      organization: {
        ...orgDetails.organization,
        [key]: value,
      },
    });
  };

  //function to get organisations
  const getOrg = async () => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/organization/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setOrgDetails(response.data);
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        if (
          (viewer || regular) &&
          (error.response.status === 404 || error.response.status === 403)
        ) {
          console.error("Error fetching data:", error);
          toast.error(
            "Access Denied: Either the project does not exist, or you do not have the necessary permissions to access it. Redirecting to project list..."
          );
          navigate("/projectlist");
        } else {
          toast.error(
            "Access Denied: Either the project does not exist, or you do not have the necessary permissions to access it."
          );
        }
      } else if (error.request) {
        console.error("Error fetching data:", error);
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  //main submit function

  const handleSubmit = async (e) => {
    const user = await getUser();

    const editedFields = Object.entries(orgDetails.organization).filter(
      ([key, value]) => {
        const initialValue = orgDetailsDefault.organization[key];
        return value !== initialValue && value !== "";
      }
    );

    if (editedFields.length > 0 ) {
     
      const requiredFields = ["name", "houseNo", "streetName", "city", "postcode"];
      const fieldLabels = {
        name: t('tabOneOrgName_editOrg'),
        houseNo: t('tabOneHouseNo_editOrg'),
        streetName: t('tabOneStreetName_editOrg'),
        city: t('tabOneCity_editOrg'),
        postcode:t('tabOnePostCode_editOrg')
       
      };
      const newValidationErrors = {};
      let hasErrors = false;
  
      requiredFields.forEach((field) => {
        if (!orgDetails.organization[field]) {
          newValidationErrors[field] = `${fieldLabels[field]} ${t('required_errorMessage')}`;
          hasErrors = true;
        }
      });
  
      if (hasErrors) {
    
        setValidationErrors(newValidationErrors);
        return;
      }
    }
       setValidationErrors({});


    setLoading(true);
    const requestBody = {
      organizationType: orgDetails.organization.organizationType ,
      name: orgDetails.organization.name ,
      houseNo: orgDetails.organization.houseNo ,
      streetName: orgDetails.organization.streetName,
      city: orgDetails.organization.city,
      postcode: orgDetails.organization.postcode,
      startDate: todayDate,
      bundleIdList: userDetails.bundleIdList,
      discount: updatedUserDetails.discount || userDetails.discount,
      minInactiveBuildingPercent:
        updatedUserDetails.minInactiveBuildingPercent ||
        userDetails.minInactiveBuildingPercent,
    };

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.put(apiUrl, requestBody, {
        headers,
      });
      if (response) {
        toast(`${t('organization_Updated')}`);
        getBundls();
        getOrg();
        navigate("/manageOrganization")
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //function to get bundls

  const getBundls = async () => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/bundle-purchase-history/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setInfo(response.data.orgBundle);
      setList(response.data.orgBundlePurchaseList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getOrg();
    getBundls();
    if (orgAdmin|| viewer||regular) {
      
      toast.error(`${t('permission_errorMessage')}`);
      navigate("/"); 
    }
  }, [viewer, navigate]);

  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="main-wrap">
        <div className="quote-body">
          <input id="slide-sidebar" type="checkbox" role="button" />
          <label for="slide-sidebar">
            <span>
              <i class="fa fa-bars">
                <img
                  class="sidebar-toggle"
                  src={hamburger}
                  alt="hamburgerBtn"
                ></img>
              </i>
            </span>
          </label>
          <div class="sidebar">
            <div class="sidebar-content">
              <div>
                <ProtectedSidebar />
              </div>
            </div>
          </div>

          <div class="portfolio">
            <div class="main-content">
              <div class="main-header-content">
                <div className="pageHeader">
                  <h1>{t('pageHeader_editOrg')}</h1>
                </div>
                <div class="user">
                  
                  <button
                    className="btn btn-light quoteBtns rqstbtn "
                    type="button"
                  >
                    
                    <img src={home} alt="home icon" />
                    <img src={rightArrow} alt="right arrow icon" />
                    <span>{t('pageHeaderBtn_editOrg')}</span>
                  </button>
                </div>
              </div>
              <div className="page-body">
                <div className="formDiv">
                  <div class="organization-details-record">
                    <div class="organization-details">
                      <div className="form-head admin-details">
                        <h2 className="pageHeading">{t('tabOneHeader_editOrg')}</h2>
                      </div>

                      <div class="organization-details-content">
                        <form onSubmit={handleSubmit}>
                          <div class="first-row">
                            <div className={`rows-content ${
                                validationErrors.name ? "error" : ""
                              }`}>
                              
                              <label>{t('tabOneOrgName_editOrg')}{" "}<span className="required">*</span></label>
                              <input
                                class="form-control"
                               
                                value={orgDetails.organization.name}
                                onChange={(e) =>
                                  updateOrg(e.target.value, "name")
                                }
                                type="text"
                              />
                              {validationErrors.name && (
                                <span className="error-message">
                                  {validationErrors.name}
                                </span>
                              )}
                            </div>
                            <div class="rows-content">
                              
                              <label>{t('tabOneOrgType_editOrg')}{" "}
                                <span className="required">*</span>
                              </label>
                              <div>
                                
                                <select
                                  className="form-control"
                                  value={orgDetails.organization.organizationType}
                                  onChange={(e) =>
                                    updateOrg(e.target.value, "organizationType")
                                  }
                                >
                                  
                                  <option>
                                    
                                    {
                                     orgDetails.organization.organizationType
                                    }
                                  </option>
                                  <option value="INSPECTION">
                                    
                                  {t('inspection__dropDownMenu')}
                                  </option>
                                  <option value="DRONEOPERATOR">
                                    
                                  {t('droneOperator_dropDownMenu')}
                                  </option>
                                  <option value="MAINTENANCE">
                                    
                                  {t('maintenance_dropDownMenu')}
                                  </option>
                                  <option value="OWNER"> 
                                    
                                  {t('owner__dropDownMenu')} </option>
                                  <option value="DIVERSE"> 
                                    
                                  {t('diverse__dropDownMenu')}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="second-row">
                            <div className={`rows-content ${
                                validationErrors.houseNo ? "error" : ""
                              }`}>
                              
                              <label>{t('tabOneHouseNo_editOrg')}{" "}<span className="required">*</span></label>
                              <input
                                class="form-control"
                                
                                value={orgDetails.organization.houseNo}
                                onChange={(e) =>
                                  updateOrg(e.target.value, "houseNo")
                                }
                                type="text"
                              />{validationErrors.houseNo && (
                                <span className="error-message">
                                  {validationErrors.houseNo}
                                </span>
                              )}
                            </div>
                            <div className={`rows-content ${
                                validationErrors.streetName ? "error" : ""
                              }`}>
                              
                              <label>{t('tabOneStreetName_editOrg')}{" "}<span className="required">*</span></label>
                              <input
                                class="form-control"
                                
                                value={orgDetails.organization.streetName}
                                onChange={(e) =>
                                  updateOrg(e.target.value, "streetName")
                                }
                                type="text"
                              />
                              {validationErrors.streetName && (
                                <span className="error-message">
                                  {validationErrors.streetName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="third-row">
                            <div className={`rows-content ${
                                validationErrors.city ? "error" : ""
                              }`}>
                              
                              <label>{t('tabOneCity_editOrg')}{" "}<span className="required">*</span></label>
                              <input
                                class="form-control"
                              
                                value={orgDetails.organization.city}
                                onChange={(e) =>
                                  updateOrg(e.target.value, "city")
                                }
                                type="text"
                              />
                              {validationErrors.city && (
                                <span className="error-message">
                                  {validationErrors.city}
                                </span>
                              )}
                            </div>
                            <div className={`rows-content ${
                                validationErrors.postcode ? "error" : ""
                              }`}>
                              
                              <label>{t('tabOnePostCode_editOrg')}{" "}<span className="required">*</span></label>
                              <input
                                class="form-control "
                               
                                value={orgDetails.organization.postcode}
                                onChange={(e) =>
                                  updateOrg(e.target.value, "postcode")
                                }
                                type="text"
                              />
                              {validationErrors.postcode && (
                                <span className="error-message">
                                  {validationErrors.postcode}
                                </span>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <AdminDetails userDetails={userDetails} id={params.id} />
                  <div class="project-leaderdetails-record">
                    <div class="projectleader-details">
                      <div class="form-head org-details">
                        <h2 class="pageHeading">{t('tabThreeHeader_editOrg')}</h2>
                        <div class="create-bundle-btn">
                          <BdlListMod
                            id={params.id}
                            userDetails={userDetails}
                            onUpdateUserDetails={handleUpdateUserDetails}
                            onSubmit={handleSubmit}
                          />
                        </div>
                      </div>

                      <PurchaseHistory id={params.id} list={list} info={info} />
                    </div>
                  </div>
                  <div class="bottom-button">
                    
                    <button
                      className="btn-back"
                      onClick={() => navigate("/manageOrganization")}
                    >
                      
                      <span>{t('backBtn')}</span>
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn-save"
                    >
                      
                      <span>{t('saveBtn')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOrganisation;
