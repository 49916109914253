import styles from "./PageUtility.module.css";
import { ProtectedSidebar } from "../../../sidebar/protectedSidebar";
import hamburger from "../../../../images/hamburger.jpg";

export const PageUtility = ({isChecked, onCheckboxChange }) => {
  return (
    <>
        <input id={styles.slideSidebar} type="checkbox" role="button" />
                <label for="slide-sidebar">
                  <span>
                    <i class="fa fa-bars">
                      <img
                        className={styles.sidebarToggle}
                        alt="sidebar"
                        src={hamburger}
                        onClick={onCheckboxChange}
                      ></img>
                    </i>
                  </span>
                </label>
                <div className={styles.jsonSidebar}>
                  <div className="sidebar-content">
                  <div>
                      <ProtectedSidebar />
                    </div>
                  </div>
                </div>
    </>
  );
};
